exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-brand-js": () => import("./../../../src/templates/brand.js" /* webpackChunkName: "component---src-templates-brand-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-functions-js": () => import("./../../../src/templates/functions.js" /* webpackChunkName: "component---src-templates-functions-js" */),
  "component---src-templates-header-frame-js": () => import("./../../../src/templates/header-frame.js" /* webpackChunkName: "component---src-templates-header-frame-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-locations-js": () => import("./../../../src/templates/locations.js" /* webpackChunkName: "component---src-templates-locations-js" */),
  "component---src-templates-news-item-js": () => import("./../../../src/templates/news-item.js" /* webpackChunkName: "component---src-templates-news-item-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-order-js": () => import("./../../../src/templates/order.js" /* webpackChunkName: "component---src-templates-order-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-stockists-js": () => import("./../../../src/templates/stockists.js" /* webpackChunkName: "component---src-templates-stockists-js" */),
  "component---src-templates-terms-and-conditions-js": () => import("./../../../src/templates/terms-and-conditions.js" /* webpackChunkName: "component---src-templates-terms-and-conditions-js" */),
  "component---src-templates-whatson-js": () => import("./../../../src/templates/whatson.js" /* webpackChunkName: "component---src-templates-whatson-js" */)
}

