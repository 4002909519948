module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"pgi8gwo"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gravity-forms/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.400gradi.com.au/graphql","api":{"key":"ck_cc67d16bf4dccac73f1e3c950491a971ff1d5003","secret":"cs_bbc9b568e1142a379202234cc64c55c776420d89"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"162950399883747","enable":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W4RZDC9","includeInDevelopment":false,"routeChangeEventName":"viewPage","enableWebVitalsTracking":true,"defaultDataLayer":null,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-209293462-1"],"gtagConfig":{"anonymize_ip":false,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"400 Gradi","short_name":"Gradi","start_url":"/400","background_color":"#100f0d","theme_color":"#100f0d","display":"standalone","icon":"src/assets/images/gradi.svg","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"b738751a2968e52a9de4d07bf01c2caf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.400gradi.com.au/graphql","verbose":true,"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"debug":{"graphql":{"writeQueriesToDisk":true,"showQueryVarsOnError":false,"showQueryOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
